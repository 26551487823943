<template>
  <v-form v-model="formIsValid" @submit.prevent="submit">
    <v-row>
      <v-col>
        <v-card
          v-for="(objectiveField, index) in sortedObjectiveFields"
          :key="index"
          class="mb-5 rounded-lg"
        >
          <v-card-title>
            Objective No. {{ objectiveField.order }}

            <v-spacer />

            <v-icon
              class="ml-5"
              title="Delete"
              @click="
                new_stg_objs.splice(
                  new_stg_objs.findIndex(
                    (el) => el.order === objectiveField.order
                  ),
                  1
                )
              "
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-divider />

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="objectiveField.title"
                    :rules="[rules.required]"
                    hide-details="auto"
                    label="Title"
                    outlined
                  />

                  <v-text-field
                    v-model="objectiveField.day_limit"
                    :rules="[rules.required]"
                    class="mt-9"
                    hide-details="auto"
                    label="Day Limit"
                    type="number"
                    outlined
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-textarea
                    v-model="objectiveField.description"
                    hide-details="auto"
                    label="Description"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="objectiveField.order"
                    :rules="[rules.required]"
                    hide-details="auto"
                    label="Order"
                    type="number"
                    disabled
                    outlined
                  />
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select 
                    v-model="objectiveField.predecessor" 
                    :items="objective_predecessors(index)" 
                    hide-details="auto" label="Predecessor" outlined 
                  />
                </v-col>
              </v-row>

              <v-card
                v-for="(task, index) in objectiveField.stg_obj_tsks"
                :key="index"
                class="my-5 rounded-lg"
                style="background-color: #111"
              >
                <v-card-title>
                  Task No. {{ task.order }}

                  <v-spacer />

                  <v-icon
                    @click="
                      objectiveField.stg_obj_tsks.splice(index, 1)
                    "
                  >
                    mdi-close
                  </v-icon>
                </v-card-title>

                <v-divider />

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="task.title"
                          :rules="[rules.required]"
                          hide-details="auto"
                          label="Title"
                          outlined
                        />

                        <v-text-field
                          v-model="task.day_limit"
                          :rules="[rules.required]"
                          class="mt-9"
                          hide-details="auto"
                          label="Day Limit"
                          type="number"
                          outlined
                        />
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-textarea
                          v-model="task.description"
                          hide-details="auto"
                          label="Description"
                          outlined
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="task.order"
                          :rules="[rules.required]"
                          hide-details="auto"
                          label="Order"
                          type="number"
                          disabled
                          outlined
                        />
                      </v-col>
                      
                      <v-col>
                        <v-select v-model="task.predecessor" :items="task_predecessors(objectiveField.order, index)" 
                          hide-details="auto" label="Predecessor" outlined />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-select
                          v-model="task.selected_assignees"
                          :items="assignees"
                          item-value="id"
                          item-text="username"
                          :rules="[rules.required]"
                          label="Assignee"
                          multiple
                          outlined
                        />
                      </v-col>
                    </v-row>

                    <v-card
                      v-for="(helper, index) in task.stg_hlps"
                      :key="index"
                      class="mb-5 rounded-lg"
                    >
                      <v-card-title>
                        helper No.
                        {{ index + 1 }}

                        <v-spacer />

                        <v-icon @click="task.stg_hlps.splice(index, 1)">
                          mdi-close
                        </v-icon>
                      </v-card-title>

                      <v-divider />

                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-text-field
                                v-model="helper.title"
                                :rules="[rules.required]"
                                hide-details="auto"
                                label="Title"
                                outlined
                              />

                              <v-select
                                v-model="helper.component"
                                :items="components"
                                :rules="[rules.required]"
                                class="mt-9"
                                hide-details="auto"
                                label="Component"
                                outlined
                              />
                            </v-col>

                            <v-col cols="12" sm="6">
                              <v-textarea
                                v-model="helper.description"
                                hide-details="auto"
                                label="Description"
                                outlined
                              />
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                  </v-container>

                  <v-divider />
                  <v-card-actions>
                    <v-row>
                      <v-col>
                        <v-btn
                          :disabled="
                            objectiveField.title === '' || task.title === ''
                          "
                          @click="addHelper(objectiveField.order, task.order)"
                        >
                          <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
                          add helper
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card-text>
              </v-card>
            </v-container>

            <v-divider />
            <v-card-actions>
              <v-row>
                <v-col>
                  <v-btn
                    :disabled="objectiveField.title === ''"
                    @click="addTask(index)"
                  >
                    <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
                    add task
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn @click="addObjective">
          <v-icon class="mr-2 text-button"> mdi-plus </v-icon>
          add objective
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn :disabled="!formIsValid || formIsSubmitting" type="submit">
          <v-progress-circular
            v-show="formIsSubmitting"
            class="mr-2"
            color="primary"
            indeterminate
          />
          Submit
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import API from "@/plugins/API";

export default {
  props: {
    stage_item: {
      type: Object,
      required: true,
    },

    assignees: {
      type: Array,
    },
  },

  data: () => ({
    search: "",

    formIsValid: false,
    formIsSubmitting: false,
    formIsSubmitted: false,
    formIsError: false,

    components: ["component 1", "component 2", "component 3"],

    new_stg_objs: [],

    stg_objs: [],

    rules: {
      required: (value) => !!value || "Required.",
    },
  }),

  computed: {
    filteredItems() {
      return this.stage_item.stg_objs.filter((item) =>
        item.title.toLowerCase().includes(this.search.toLowerCase())
      );
    },

    sortedObjectiveFields() {
      if (this.new_stg_objs.length !== 0) {
        const res = [...this.new_stg_objs];

        res.sort((a, b) => a.order - b.order);

        return res;
      }
      return [];
    },
  },

  methods: {
    async picked_objective(id) {
      try {
        const res = await API().get(`api/kanban/read_stage_objective/?stg_obj_id=${id}`);

        const objective = res.data;

        console.log('picked objective: ',objective);

        let order = null;
        
        if (this.new_stg_objs.length === 0) {
          order = this.get_highest_order(this.stage_item.stg_objs) + 1;
        } else {
          order = this.get_highest_order(this.new_stg_objs) + 1;
        }
        
        this.new_stg_objs.push({
          title: objective.title,
          description: objective.description,
          day_limit: objective.day_limit,
          order: order,
          predecessor: objective.predecessor,
          stg_obj_tsks: objective.stg_obj_tsks,
        });

        this.new_stg_objs.forEach(objective => {
          let i = 1;
          objective.stg_obj_tsks.forEach(task => {
            task.order = i;
            i++;
          });
        });
      } catch (err) {
        console.log(err);
        this.formIsError = true;
        setTimeout(() => {
          this.formIsError = false;
        }, 2000);
      }
    },

    objective_predecessors(index){
      const res = [ { value: 0, text: 'None' } ];

      if(this.stage_item.stg_objs.length > 1) {
        // loop through all objectives
        this.stage_item.stg_objs.forEach((objective) => {
          res.push({
            text: objective.title,
            value: Number(objective.order),
          });
        });
      }

      if(this.sortedObjectiveFields.length > 1){
        // filter all objectives except for the current one
        const objectives = this.sortedObjectiveFields.filter((el, i) => i !== index);

        // loop through all objectives
        objectives.forEach((objective) => {
          res.push({
            text: objective.title,
            value: Number(objective.order),
          });
        });
      }

      return res;
    },

    task_predecessors(objective_order, task_index){
      const objective_index = this.sortedObjectiveFields.findIndex((el) => el.order === objective_order);

      const res = [ { value: 0, text: 'None' } ];

      if(this.sortedObjectiveFields[objective_index].stg_obj_tsks.length > 1){
        // filter all objective tasks except for the current one
        const tasks = this.sortedObjectiveFields[objective_index].stg_obj_tsks.filter((el, i) => i !== task_index);

        // loop through all tasks
        tasks.forEach((task) => {
          res.push({
            text: task.title,
            value: Number(task.order),
          });
        });
      }

      return res;
    },

    addObjective() {
      // if new objectives has no items, set order to the highest order of the stage objectives + 1
      // else set order to the highest order of the new objectives + 1
      let order = null;

      if (this.new_stg_objs.length === 0) {
        order = this.get_highest_order(this.stage_item.stg_objs) + 1;
      } else {
        order = this.get_highest_order(this.new_stg_objs) + 1;
      }
      
      this.new_stg_objs.push({
        title: "",
        description: "",
        day_limit: null,
        order: order,
        predecessor: null,

        stg_obj_tsks: [],
      });
    },

    addTask(index) {
      this.sortedObjectiveFields[index].stg_obj_tsks.push({
        title: "",
        description: "",
        day_limit: null,
        order:
          this.get_highest_order(
            this.sortedObjectiveFields[index].stg_obj_tsks
          ) + 1,
        predecessor: null,
        selected_assignees: [],

        stg_hlps: [],
      });
    },

    addHelper(objective_order, task_order) {
      const obj_index = this.sortedObjectiveFields.findIndex(
        (el) => el.order === objective_order
      );

      const task_index = this.sortedObjectiveFields[
        obj_index
      ].stg_obj_tsks.findIndex((el) => el.order === task_order);

      this.sortedObjectiveFields[obj_index].stg_obj_tsks[
        task_index
      ].stg_hlps.push({
        title: "",
        description: "",
        component: null,
      });
    },

    get_highest_order(objectives) {
      if (objectives.length === 0) return 0;

      const highestOrder = objectives.reduce((maxOrder, task) => {
        return Math.max(maxOrder, task.order);
      }, 0);

      return highestOrder;
    },

    async submit() {
      try {
        this.formIsSubmitting = true;

        const submit_res = await API().post("api/kanban/create_stage_objective", {
          stg_itm_id: this.stage_item.id,
          stg_objs: this.sortedObjectiveFields,
        });

        console.log("this will be submitted: ", this.sortedObjectiveFields);

        if(submit_res.status === 201) console.log("successfully submitted");

        this.$emit("submit-notification");
        
        this.formIsSubmitting = false;
      } catch (err) {
        console.log(err);
        this.formIsError = true;
        setTimeout(() => {
          this.formIsError = false;
        }, 2000);

        this.formIsSubmitting = false;
      }
    },
  },
};
</script>
