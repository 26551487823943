<template>
  <v-container>
    <v-row v-if="pageIsLoading">
      <v-col>
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert v-show="formIsSubmitted" transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px" color="success" type="success">
      Successfully submitted!
    </v-alert>

    <v-alert
      v-show="pageIsError"
      transition="fade-transition"
      style="z-index: 1; position: fixed; right: 15px; min-width: 300px"
      color="error"
      type="error"
    >
      An error occurred!
    </v-alert>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col class="d-flex align-center">
        <v-icon
          class="grey--text text-h4 mr-5"
          title="Go back to kanban stage"
          @click="
            $router.push({
              path: `/dashboard/admin/kanban/${kanban_type}/stage-item/${stage_item_id}/objective/list/`,
            })
          "
        >
          mdi-arrow-left-circle
        </v-icon>

        <label class="text-h5">
          <span>
            {{ stg_itm.kbn_stg.stage_name }}/ Add Objective/
          </span>
          <span v-if="kanban_type === 'Deal'" class="grey--text">
            ( {{ stg_itm.deal.public_name }} )
          </span>
          <span v-if="kanban_type === 'Lender on boarding'" class="grey--text">
            ( {{ stg_itm.company.name }} )
          </span>
        </label>
      </v-col>
    </v-row>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col>
        <p class="text-justify">
          Define your goal: Clearly establish what you want to achieve through
          the deal. This could be securing a certain price, obtaining specific
          terms, expanding your customer base, increasing revenue, or improving
          your market position. Make sure your objective is specific,
          measurable, achievable, relevant, and time-bound (SMART).
        </p>

        <v-tabs v-model="tab" icons-and-text class="green">
          <v-tab href="#manual_add">
            Add Manually
            <v-icon>mdi-plus</v-icon>
          </v-tab>

          <v-tab href="#quick_add">
            Quick Add
            <v-icon>mdi-flash-outline</v-icon>
          </v-tab>
        </v-tabs>

        <v-divider />

        <v-tabs-items v-model="tab" class="pa-5 bordered">
          <v-tab-item value="manual_add">
            <StageObjectiveForm
              :stage_item="stg_itm"
              :assignees="assignees"
              @submit-notification="handleSubmitNotification"
              ref="stageObjectiveForm"
            />
          </v-tab-item>

          <v-tab-item value="quick_add">
            <v-row>
              <v-col>
                <v-alert type="warning" v-if="stg_itm.stg_objs.length === 0"> 
                  There are no existing objective for this stage item
                </v-alert>
                
                <v-tabs vertical>
                  <v-tab
                    v-for="objective in stg_itm.stg_objs"
                    :key="objective.id"
                  >
                    {{ objective.title }}
                  </v-tab>

                  <v-tab-item
                    v-for="objective in stg_itm.stg_objs"
                    :key="objective.id"
                  >
                    <v-card flat style="background-color: #111">
                      <v-card-title>
                        <v-spacer />
                        <v-btn @click="pick_objective(objective.id)">
                          <v-icon>mdi-gesture-tap</v-icon> pick
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-simple-table>
                          <thead>
                            <tr>
                              <th class="white--text text-center" :width="200">
                                FIELDS
                              </th>
                              <th class="white--text text-center">DETAILS</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>TITLE</td>
                              <td>{{ objective.title }}</td>
                            </tr>

                            <tr>
                              <td>DESCRIPTION</td>
                              <td>{{ objective.description }}</td>
                            </tr>

                            <tr>
                              <td>DAY LIMIT</td>
                              <td>
                                {{ objective.day_limit }}
                                {{ objective.day_limit > 0 ? "days" : "day" }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>

                      <v-card-text>
                        <v-row>
                          <v-col class="task-style d-flex">
                            <h2 v-if="objective.stg_obj_tsks.length === 0" class="grey--text"> 
                              ( No task yet )
                            </h2>

                            <v-card
                              v-for="(
                                task, index
                              ) in objective.stg_obj_tsks"
                              :key="task.id"
                              class="mr-5"
                              style="min-width: 350px; max-width: 350px"
                            >
                              <v-card-title>{{ task.title }}</v-card-title>

                              <v-card-text>
                                <div>{{ task.description }}</div>
                              </v-card-text>

                              <v-card-text>
                                <div>
                                  <v-icon> mdi-calendar-clock </v-icon>
                                  Target: {{ task.day_limit }}
                                  {{ task.day_limit > 0 ? "days" : "day" }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import StageObjectiveForm from "@/components/StageObjectiveForm.vue";

export default {
  props: ["kanban_type", "stage_item_id"],
  components: { StageObjectiveForm },

  data: () => ({
    selectedItem: null,
    tab: "",
    pageIsLoading: true,
    pageIsError: false,
    formIsSubmitted: false,

    assignees: [],

    stg_itm: {},
  }),

  methods: {
    predecessors(current_order) {
      const existing_objectives = this.stg_itm.stg_objs
        .filter((objective) => objective.order !== current_order)
        .map(({ order, title }) => ({ order, title }));
      const new_objectives = this.new_stg_objs
        .filter((objective) => objective.order !== current_order)
        .map(({ order, title }) => ({ order, title }));
      return [...existing_objectives, ...new_objectives];
    },

    pick_objective(id) {
      this.tab = "manual_add";
      this.$refs.stageObjectiveForm.picked_objective(id);
    },
    
    handleSubmitNotification(){
      this.formIsSubmitted = true;

      setTimeout(() => {
          this.formIsSubmitted = false;
        }, 2000);

      setTimeout(() => {
        this.$router.push({
            path: `/dashboard/admin/kanban/${this.kanban_type}/stage-item/${this.stage_item_id}/objective/list/`,
          })
      }, 3000)
    },

    async readStageItem() {
      try {
        this.pageIsLoading = true;
        const res = await API().get(
          `api/kanban/read_stage_item/?stg_itm_id=${this.stage_item_id}`
        );

        console.log(res.data);

        this.stg_itm = res.data;

        const res_users = await API().get("api/kanban/get_internal_users");

        this.assignees = res_users.data;

        this.pageIsLoading = false;
      } catch (error) {
        console.log(error);
        this.pageIsError = true;
        setTimeout(() => {
          this.pageIsError = false;
        }, 2000);
      }
    },
  },
  mounted() {
    this.readStageItem();
  },
};
</script>

<style>
.bordered {
  border: 1px solid rgb(51, 51, 51);
  padding: 5px;
}

.objective-container {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* height */
.objective-container::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.objective-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.objective-container::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.objective-container::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}

.circle .v-input--selection-controls__ripple {
  border-radius: 50%;
}

.circle .v-input--selection-controls__input {
  border-radius: 50%;
}

.circle .v-icon {
  border-radius: 50%;
}

.task-style {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* height */
.task-style::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.task-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.task-style::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.task-style::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}
</style>
